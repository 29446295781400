@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
    position: relative;
    overflow: hidden;
    background: $color-black;
    border-bottom: 1px solid $color-white;
}

.innerContainer {
    max-width: $page-content-lg-max-width;
    margin: 0 auto;

    @include md-min {
      display: flex;
    }

    @include lg-min {
      padding: 0 $space-lg;
    }
}

.itemContainer {
    position: relative;
    background: $color-black;
    padding-bottom: $space-lg;

    @include md-min {
    flex-basis: 50%;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: $color-white;
        z-index: 1;
      }

      &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.itemInnerContainer {
  height: fit-content;
  position: relative;

  &::before,
  &::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    left: -50%;
    right: -50%;
    background-color: $color-white;
    z-index: 1;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

/**
 * This class only styles the content when the column is empty.
 */
.addContent {
  position: relative;
  z-index: 1;
  margin: 5%;
  border: none !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  height: 80%;
  min-height: 200px;
  width: 100%;
}
